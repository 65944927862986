var recaptcha_sitekey = '6LcGZiUUAAAAAEpAmnOZRw_-txmg9VOvKDydS2Ih';

var onloadCallback = function() {
	if(document.getElementById('contact_captcha') !== null){
		grecaptcha.render(document.getElementById('contact_captcha'), {
			'sitekey' : recaptcha_sitekey,
			'callback' : function(){
				$('#contact_form')[0].submit();
			}
		});
	}
	if(document.getElementById('returns_captcha') !== null){
		grecaptcha.render(document.getElementById('returns_captcha'), {
			'sitekey' : recaptcha_sitekey,
			'callback' : function(){
				$('#returns_form')[0].submit();
			}
		});
	}
};

$.validator.addMethod(
        'regex',
        function(value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || !value.match(re);
        },
        'Please enter valid input.'
);
$.validator.addMethod(
        'phoneUS',
        function (a, b) {
                return a = a.replace(/\s+/g, ''),
                this.optional(b) || a.length > 9 && a.match(/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/)},
        'Please specify a valid phone number'
);
 
$("#contact_form").validate({
        debug:false,
        submitHandler: function(){
			grecaptcha.reset();
			$('#contact_captcha').click();
        },
        errorClass: "invalid",
        validClass: "valid",
        rules:{
                email: {
                        required: true,
                        email: true
                },
                phone: {
                        required:true,
                        phoneUS: true
                }
        },
        messages:{
				subject: "Please enter subject",
				comments: "Please enter your comments",
                name: "Please enter your name",
                phone: {
                        required: "Please enter your phone number",
                        phoneUS: "Please enter a valid phone number: XXX-XXX-XXXX"
                },
                email: {
                        required: "Please enter your email",
                        email: "Please enter a valid email"
                }
        }
});

$("#returns_form").validate({
        debug:false,
        submitHandler: function(){
			grecaptcha.reset();
			$('#returns_captcha').click();
        },
        errorClass: "invalid",
        validClass: "valid",
        rules:{
                email: {
                        required: true,
                        email: true
                },
                phone: {
                        required:true,
                        phoneUS: true
                }
        },
        messages:{
				onum: "Please enter your order number",
                comments: "Please enter your comments",
                name: "Please enter your name",
                phone: {
                        required: "Please enter your phone number",
                        phoneUS: "Please enter a valid phone number: XXX-XXX-XXXX"
                },
                email: {
                        required: "Please enter your email",
                        email: "Please enter a valid email"
                }
        }
});
