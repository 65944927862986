// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}

$('.btn-scroll').click(function(){
    $('html, body').animate({scrollTop: $('#frontpage_form').offset().top}, 'slow');
});

// Zip Autofill Configs
var zipAutofillInit = {
    'key':'AIzaSyAu_devqgFfFDvpYkTJimHzJVHiwEHjmYA',
    'selectors': '#frontpage_form',
    'country':'US',
    'citySelectAttr' : {
        'class':'form-control',
        'id':'cShipCity',
        'name':'cShipCity',
        'required': true
    },
    'cityInputAttr': {
        'type':'text',
        'placeholder':'City',
        'class':'form-control',
        'id':'cShipCity',
        'name':'cShipCity',
        'required': true
    }
}

// CountDown
var timeoutHandle;
function countdown(minutes) {
    var seconds = 60;
    var mins = minutes
 
    function tick() {
        var counter = document.getElementById("counter");
        var current_minutes = mins - 1
        seconds--;
        counter.innerHTML =
            current_minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
        if (seconds > 0) {
            timeoutHandle = setTimeout(tick, 1000);
        } else {
 
            if (mins > 1) {
                setTimeout(function () {
                    countdown(mins - 1);
                }, 1000);
 
            }
        }
    }
    tick();
}
countdown(15);